// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'development',
  version: '1.6.7',
  baseUrl: 'https://api-dev.stilio.app/api',
  stilioUrl: 'https://salon-dev.stilio.app',
  mediaUrl: 'https://media-dev.stilio.app',
  termsPath: '/docs/terms.pdf',
  policyPath: '/docs/privacy.pdf',
  landingUrl: 'https://dev.stilio.md',
  sentryDsn: 'https://e972d879a7334aa0bd2090ec11ed1c8f@o425559.ingest.sentry.io/6467780',
  posthogDsn: 'phc_7dc5buDnGbNreeureWsXIY31tp0pYYQYkxYaT3pMbg',
  posthogHost: 'https://eu.posthog.com/',
  maibCallback: 'https://maib.ecommerce.md:21443/ecomm/ClientHandler'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
